<template>
  <div v-html="formattedHtml"></div>
</template>

<script>
import router from "@/router";

export default {
  name: "FormattedHtml",
  props: {
    html: {
      type: String,
      required: true
    }
  },
  computed: {
    formattedHtml() {
      // Nimm den HTML-Inhalt entgegen und wende die Transformation an
      let source = this.html;
      router.getRoutes().forEach((route) => {
        source = source.replaceAll(`href="${route.name}"`, `href="${route.path}"`);
        source = source.replaceAll(`(${route.name})`, `(${route.path})`);
      });
      return source;
    }
  }
};
</script>
