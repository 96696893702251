<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="card card-default animated-fast fadeInLeft" :class="loading ? 'whirl' : ''">
                <div class="card-header text-bold">
                    <i class="fad fa-comment-dots"></i> {{$t('support.view.ticket.title')}}
                </div>
                <div class="card-body" v-if="ticket.label">

                    <b-row style="max-width: 700px">
                        <b-col sm="12">
                            <b>{{$t('support.view.ticket.subject')}}:</b> {{ ticket.title }}
                        </b-col>
                        <b-col sm="6">
                            <b>{{$t('support.view.ticket.created_at')}}:</b> {{ $moment.utc(ticket.created_at).local().format('LLL') }}
                        </b-col>
                        <b-col sm="6">
                            <b>{{$t('support.view.ticket.status')}}:</b> {{ ticket.status }}
                        </b-col>
                        <b-col sm="6">
                            <b>{{$t('support.view.ticket.id')}}:</b> #{{ ticket.label }}
                        </b-col>
                    </b-row>

                    <hr>

                    <b-btn class="mr-2" :to="{name: 'Support:Tickets'}">{{ $t('support.view.ticket.button_back') }}</b-btn>

                    <template v-if="ticket.status !== 'closed'">
                        <b-btn class="mr-2" variant="danger" @click="closeTicket()">{{ $t('support.view.ticket.button_close') }}</b-btn>

                        <p class="text-sm mt-2">
                          {{ $t('support.view.ticket.button_close_hint') }}
                        </p>
                    </template>

                    <hr>

                    <div class="comment  mb-2">
                        <p><b>{{ $store.getters.user('display_name') }}</b> - {{ $moment.utc(ticket.created_at).local().format('LLL') }}</p>
                        <div class="border p-2">{{ ticket.description }}</div>
                    </div>

                    <div class="comment mt-4" v-for="(response, index) in ticket.responses" :key="'res' + index">

                      <div v-if="response.responder_type !== 'user'" class="text-right">
                        <p>{{ $moment.utc(response.created_at).local().format('LLL') }} - <b>{{ response.responder_name }}</b></p>
                      </div>
                      <div v-else>
                        <p><b>{{ response.responder_name }}</b> - {{ $moment.utc(response.created_at).local().format('LLL') }}</p>
                      </div>

                      <div class="border p-2" >
                        <formatted-html :html="response.response_html"></formatted-html>
                      </div>
                    </div>
                    <div v-if="ticket.status !== 'closed'" class="mt-4">
                        {{ $t('support.view.ticket.field_answer') }}:
                        <b-textarea class="my-2" style="min-height: 150px" v-model="answer"></b-textarea>
                        <div class="text-right">
                          <b-btn class="ml-2" variant="primary" @click="sendAnswer(answer, false)" :disabled="loading">{{ $t('support.view.ticket.button_send') }}</b-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ContentWrapper>
</template>
<style lang="scss" scoped>
    .comment {

    }
</style>
<script>
    import FormattedHtml from "@/components/Common/FormattedHtml.vue";

    export default {
        data() {
            return {
                ticket: [],
                answer: '',
                loading: true
            }
        },
        components: {FormattedHtml},
        beforeMount() {
            this.loading = true;
            this.$api.get('support/tickets/' + this.$route.params.label).then(response => {
                this.ticket = response.data;
                this.loading = false;
            });
        },
        methods: {
            formatResponse(response) {
                return response.replace(/\n/g, '<br>');
            },
            isMyComment(comment) {
                return comment.user.id === this.$store.getters.user('id')
            },
            sendAnswer(answer, solved = false) {
                this.loading = true;
                this.$api.post('support/tickets/' + this.ticket.label + '/response', {response: answer, solved: solved}).then(response => {
                    this.ticket = response.data;
                    this.loading = false;
                })
            },
            closeTicket() {
              this.loading = true;
              this.$api.post('support/tickets/' + this.ticket.label + '/close').then(response => {
                this.ticket = response.data;
                this.loading = false;
              })
            }
        }
    }
</script>