<template>
  <div>
    <!-- Hauptbanner -->
    <div class="dashboard-banner animated-fast fadeInDown">
      <!-- Overlay für bessere Lesbarkeit -->
      <div class="overlay"></div>

      <!-- Banner Inhalt -->
      <div class="banner-content text-center">
        <h1 class="banner-title">Ticketverkauf</h1>
        <p class="banner-description">
          Sichere dir jetzt deine Tickets für die letzten verfügbaren Pflanzen und <br/>
          sichere dir eine kostenlose Bonuspflanze!
        </p>
        <router-link :to="{name: 'Growing:Tickets'}" class="btn btn-primary btn-lg">
          Jetzt Tickets kaufen <i class="fas fa-ticket-alt ml-2"></i>
        </router-link>
      </div>
    </div>

    <!-- Exklusiver Hinweis -->
    <div class="exclusive-note text-center mb-4">
      <p class="note-text">
        Dieses Angebot ist exklusiv für Schweizer Kunden.<br/>
        Angebot endet in: <span>{{ countdown }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicketSaleBanner',
  data() {
    return {
      countdown: '',
      saleEndDate: new Date('2025-01-12T23:59:59'), // 12. Januar um Mitternacht
    };
  },
  mounted() {
    this.updateCountdown();
    this.interval = setInterval(this.updateCountdown, 1000); // Aktualisierung jede Sekunde
  },
  beforeDestroy() {
    clearInterval(this.interval); // Timer bei Komponentenzerstörung aufräumen
  },
  methods: {
    updateCountdown() {
      const now = new Date();
      const timeRemaining = this.saleEndDate - now;

      if (timeRemaining <= 0) {
        this.countdown = 'Das Angebot ist abgelaufen!';
        clearInterval(this.interval);
        return;
      }

      const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

      this.countdown = `${days} Tage, ${hours} Stunden, ${minutes} Minuten, ${seconds} Sekunden`;
    },
  },
};
</script>

<style scoped>
.dashboard-banner {
  position: relative;
  background-image: url('/img/banner/banner_ticket_sale.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 80px 20px;
  border-radius: 10px;
  overflow: hidden;
}

.dashboard-banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dunkler Overlay für bessere Lesbarkeit */
  border-radius: 10px;
  z-index: 1;
}

.banner-content {
  position: relative;
  z-index: 2;
}

.banner-title {
  font-size: 3rem;
  font-weight: bold;
}

.banner-description {
  font-size: 1.25rem;
  margin-top: 20px;
}

.btn-primary {
  margin-top: 30px;
  padding: 15px 30px;
  font-size: 1.1rem;
  border-radius: 50px;
  transition: background-color 0.3s, transform 0.3s;
}

.btn-primary:hover {
  background-color: #fff;
  color: #000000;
  transform: scale(1.05);
}

.exclusive-note {
  padding: 10px 20px;
  background-color: #f8f9fa; /* Heller Hintergrund */
  border-radius: 5px;
}

.note-text {
  font-size: 1rem;
  color: #333;
  font-style: italic;
}

@media (max-width: 768px) {
  .banner-title {
    font-size: 2rem;
  }
  .banner-description {
    font-size: 1rem;
  }
  .btn-primary {
    padding: 10px 20px;
    font-size: 1rem;
  }
  .note-text {
    font-size: 0.9rem;
  }
}
</style>
