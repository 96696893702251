<template>
    <header class="topnavbar-wrapper">
        <!-- START Top Navbar-->
        <nav class="navbar topnavbar">
            <!-- START navbar header-->
            <div class="navbar-header">
                <router-link tag="a" :to="{name: 'Dashboard'}" class="navbar-brand">
                    <div class="brand-logo" style="padding: 0px 20px">
                        <img class="img-fluid" src="img/logo/cannerald-white.svg" alt="App Logo"/>
                    </div>
                    <div class="brand-logo-collapsed">
                        <img class="img-fluid" src="img/logo/cannerald-compact-small-white.svg" alt="App Logo">
                    </div>
                </router-link>
            </div>
            <!-- END navbar header-->
            <!-- START Left navbar-->
            <ul class="navbar-nav mr-auto flex-row">
                <li class="nav-item">
                    <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
                    <a href="" class="nav-link d-none d-md-block d-lg-block d-xl-block text-white" @click.prevent="toggleAsideCollapsed">
                        <em class="fad fa-bars"></em>
                    </a>
                    <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
                    <a href="" class="nav-link sidebar-toggle d-md-none text-white" @click.prevent="toggleOffcanvas">
                        <em class="fad fa-bars"></em>
                    </a>
                </li>
                <!-- START User avatar toggle-->
                <!--li class="nav-item d-none d-md-block">
                    <a class="nav-link" href="" @click.prevent="toggleUserBlock">
                        <em class="icon-user"></em>
                    </a>
                </li-->
                <!-- END User avatar toggle-->
                <!-- START lock screen-->
                <li class="nav-item d-none d-md-block">
                    <a @click.prevent="lock()" href="" class="nav-link">
                        <em class="icon-lock"></em>
                    </a>
                </li>
                <!-- END lock screen-->
            </ul>
            <!-- END Left navbar-->
            <!-- START Right Navbar-->
            <ul class="navbar-nav flex-row">

                <li class="nav-item" v-show="client_update_available">
                    <a href="" @click.prevent="reloadPage()" v-b-tooltip.hover title="Reload page to apply update!" class="text-danger nav-link">Update available.</a>
                </li>

                <li class="nav-item" v-if="tickets.unread_count">
                    <a href="" @click.prevent="openTicket()" class="nav-link">
                        <em class="fad fa-user-headset text-white"></em>
                        <span class="badge badge-light">1</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a href="" @click.prevent="openConversations()" class="nav-link ">
                        <em class="fad fa-envelope-open text-white" v-show="!messages.unread_count"></em>
                        <em class="fad fa-envelope text-white" v-show="messages.unread_count"></em>
                        <span class="badge badge-light" v-show="messages.unread_count">{{ messages.unread_count }}</span>
                    </a>
                </li>

                <!-- START Alert menu-->


                <!-- START Alert menu-->
                <b-nav-item-dropdown ref="notifications" class="dropdown-list dropdown-notifications" no-caret right>
                    <template slot="button-content">
                        <em class="fad fa-bell text-white"></em>
                        <span class="badge badge-light text-white" v-show="notifications.unread_count">{{ notifications.unread_count }}</span>
                    </template>

                    <div class="list-group">

                        <div class="list-group-item text-primary" v-show="!notifications.unread.data.length && !notifications.unread.loading">
                            {{$t('common.layout.header.notifications.no_new')}}
                        </div>

                        <div class="list-group-item" v-show="notifications.unread.data.length || notifications.unread_count">
                            {{$t('common.layout.header.notifications.new')}}

                            <a class="float-right" href="" @click.prevent="markAllAsRead()">{{$t('common.layout.header.notifications.mark_as_read')}}</a>

                        </div>

                        <div class="list-group-item" v-for="notification in notifications.unread.data">
                            <b-row class="m-0">
                                <b-col cols="1" class="text-center align-self-center p-0"
                                       :class="'text-' + (notification.data.status ? (notification.data.status === 'success' ? 'primary' : notification.data.status) : 'primary') ">
                                    <i class="fa-2x" :class="notification.data.class"></i>
                                </b-col>
                                <b-col>
                                    <div class="clearfix">
                                        <p class="m-0 text-muted text-sm float-right">{{ $moment.utc(notification.created_at).local().fromNow() }} </p>
                                        <p class="m-0">{{ notification.data.title }}</p>
                                    </div>
                                    <p class="m-0 text-muted text-sm">{{ notification.data.description }}</p>
                                </b-col>
                            </b-row>
                        </div>

                        <div class="list-group-item list-group-item-action whirl" v-show="notifications.unread.loading" style="height: 80px"></div>

                        <a class="list-group-item list-group-item-action" :disabled="notifications.read.loading" v-show="notifications.unread.next_page_url"
                           @click.prevent="loadMoreNotifications('unread')" href="">
                            <span class="d-flex align-items-center">
                                <span class="text-sm">{{$t('common.layout.header.notifications.more')}}</span>
                                <span class="badge badge-danger ml-auto">{{ notifications.unread.total - notifications.unread.to }}</span>
                            </span>
                        </a>

                        <div class="list-group-item" v-show="notifications.read_count">
                            {{$t('common.layout.header.notifications.history')}}
                        </div>

                        <div class="list-group-item" v-for="notification in notifications.read.data">
                            <b-row class="m-0">
                                <b-col cols="1" class="text-center align-self-center p-0"
                                       :class="'text-' + (notification.data.status ? (notification.data.status === 'success' ? 'primary' : notification.data.status) : 'primary') ">
                                    <i class="fa-2x" :class="notification.data.class"></i>
                                </b-col>
                                <b-col>
                                    <div class="clearfix">
                                        <p class="m-0 text-muted text-sm float-right">{{ $moment.utc(notification.created_at).local().fromNow() }}</p>
                                        <p class="m-0">{{ notification.data.title }}</p>
                                    </div>
                                    <p class="m-0 text-muted text-sm">{{ notification.data.description }}</p>
                                </b-col>
                            </b-row>
                        </div>

                        <div class="list-group-item list-group-item-action whirl" v-show="notifications.read.loading" style="height: 80px"></div>

                        <a class="list-group-item list-group-item-action" :disabled="notifications.read.loading" v-show="notifications.read.next_page_url"
                           @click.prevent="loadMoreNotifications('read')" href="">
                            <span class="d-flex align-items-center">
                                <span class="text-sm">Show More</span>
                            </span>
                        </a>

                    </div>
                </b-nav-item-dropdown>

                <li class="nav-item d-block">
                    <a class="nav-link text-white" @click.prevent="logout()" href="#">
                        <i class="fad fa-power-off"></i>
                    </a>
                </li>
                <!-- END Alert menu-->
            </ul>
            <!-- END Right Navbar-->
            <HeaderSearch/>
        </nav>
        <!-- END Top Navbar-->

    </header>


</template>

<script>
    import {mapMutations} from 'vuex'
    import HeaderSearch from '@/components/Layout/HeaderSearch'
    import * as Sentry from "@sentry/vue";
    export default {
        name: 'Header',
        components: {
            HeaderSearch
        },
        data() {
            return {
                notifications: {
                    read: {
                        data: [],
                        loading: false,
                    },
                    unread: {
                        data: [],
                        loading: false
                    },
                    loading: false,
                    unread_count: 0,
                    read_count: 0,
                },
                messages: {
                    unread_count: 0,
                    unread_message_conversation_id: null
                },
                tickets: {
                    unread_count: 0,
                    unread_ticket_id: null
                },
                client_update_available: false,
            }
        },
        mounted() {
            this.$refs.notifications.$on('show', () => {
                this.openNotifications();
            });

            this.$refs.notifications.$on('hide', () => {
                this.closeNotifications();
            });

            this.loading = true;
            if (process.env.NODE_ENV === 'production') {
                Sentry.setUser({
                  id: this.$store.getters.user('id'),
                  username: this.$store.getters.user('name'),
                  email: this.$store.getters.user('email'),
                  ip_address: this.$store.getters.user('ip_address'),
                });
            }
            this.$api.get('user/header').then(response => {

                let notifications = response.data.notifications;

                this.notifications.unread_count = notifications.notifications.unread_count;
                this.notifications.read_count = notifications.notifications.read_count;
                this.messages = notifications.chat;
                this.tickets = notifications.tickets;

                this.loading = false;

                if (!this.checkTermUpdates(response.data.term_updates)) {
                  if (
                      this.$store.state.user.instruction_state.register_intro_closed &&
                      this.$store.state.user.instruction_state.android_app_closed
                  ) {
                    if (!this.$store.state.chat.realized_new_message && this.messages.unread_count > 1) {
                      this.$swal.fire({
                        icon: 'info',
                        title: this.$t('common.layout.header.messages.new_message.title'),
                        text: this.$t('common.layout.header.messages.new_message.description'),
                        confirmButtonText: this.$t('common.layout.header.messages.new_message.confirm'),
                        reverseButtons: true,
                      }).then(response => {
                        this.$store.commit('realizedNewMessage');
                        if (!response.dismiss) {
                          this.$store.commit('setCurrentConversation', this.messages.unread_message_conversation_id);
                          this.$router.push({name: 'Chat:Conversations'});
                        }
                      })
                    }
                  }
                }
            });

            this.$api.messageListener(message => {
                if (this.$route.name !== 'ChatConversations') {
                    this.messages.unread_count++;
                }
            });

            this.$api.broadcaster.private('App.Models.User.' + this.$store.state.user.id).notification(() => {
                this.notifications.unread_count++;
            });

            this.$api.broadcaster.channel('client').listen('.client.updated', () => {
                if (!this.$isApp) {
                    if (this.$route.name === 'Growing:Sale') {
                        this.client_update_available = true;
                    } else {
                        let autoUpdateTimeout = setTimeout(() => {
                            this.reloadPage();
                        }, 5000)

                        this.$swal.fire({
                            icon: 'warning',
                            title: 'Update Available',
                            text: 'App will update in a few seconds automatically',
                            showConfirmButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Apply Now',
                            cancelButtonText: 'Later ...',
                            reverseButtons: true
                        }).then((response) => {
                            if (!response.dismiss) {
                                this.reloadPage();
                            } else {
                                clearTimeout(autoUpdateTimeout);
                            }
                        });
                    }
                }
            });
        },
        methods: {
            checkTermUpdates(updated) {
                if (updated.length > 0) {

                    let html = ''

                    updated.forEach(term => {
                        html += `<p class="text-left"><i class=" fa-2x mr-2 mt-2 fad fa-file-alt float-left"></i>` + this.$t('common.layout.header.terms.text_changed', {terms: term.name}) +` <br/><a href="${term.url}" target="_blank"><i class="fad fa-arrow-alt-right mr-2 ml-4"></i>` + this.$t('common.layout.header.terms.link_open') + `</a></p>`;
                    })

                    html += '<div class="text-left">';
                    html += '<small>- ' + this.$t('common.layout.header.terms.hint_1') + '</small><br>';
                    html += '<small>- ' + this.$t('common.layout.header.terms.hint_2') + '</small>';
                    html += '</div>';

                    this.$swal.fire({
                        title: this.$t('common.layout.header.terms.title'),
                        html: html,
                        confirmButtonText: this.$t('common.layout.header.terms.button_confirm'),
                        allowOutsideClick: false,

                        input: 'checkbox',
                        inputValue: 0,
                        inputPlaceholder: this.$t('common.layout.header.terms.checkbox_label'),

                        didOpen: () => {
                            this.$swal.disableButtons();
                            const input = this.$swal.getInput();
                            input.addEventListener('change', event => {
                                if (event.target.checked) {
                                    this.$swal.enableButtons();
                                } else {
                                    this.$swal.disableButtons();
                                }
                            });
                        }
                    }).then(() =>  {
                        this.$api.post('user/terms/agree-updates');
                    });

                    return true;
                } else {
                    return false;
                }
            },
            openConversations() {
                this.messages.unread_count = 0;
                if (this.$route.name !== 'Chat:Conversations') {
                    if (this.$store.state.chat.lastConversationId) {
                        if (this.$route.name !== 'Chat:Conversation')
                            this.$router.push({name: 'Chat:Conversation', params: {conversationId: this.$store.state.chat.lastConversationId}});
                    } else {
                        if (this.$route.name !== 'Chat:Conversations')
                            this.$router.push({name: 'Chat:Conversations'});
                    }
                }
            },

            openNotifications() {
                if (this.notifications.unread_count) {
                    this.notifications.unread.loading = true;
                    this.$api.get('user/notifications/unread').then(response => {
                        this.mergeNotifications('unread', response);
                        this.notifications.unread.loading = false;
                        this.refreshUnreadCount();
                    });
                }

                if (this.notifications.read_count) {
                    this.notifications.read.loading = true;
                    this.$api.get('user/notifications/read').then(response => {
                        this.notifications.read.loading = false;
                        this.mergeNotifications('read', response);
                    });
                }
            },
            openTicket() {
                this.tickets.unread_count = 0;
                this.$router.push({name: 'Support:Ticket', params: {id: this.tickets.unread_ticket_id}});
            },
            closeNotifications() {
                if (this.notifications.unread && this.notifications.unread.to > 0) {
                    this.$api.post('user/notifications/mark-as-read', {amount: this.notifications.unread.to});
                    this.notifications.read_count += this.notifications.unread.to;
                }
            },
            markAllAsRead() {
                this.notifications.unread.to = this.notifications.unread.total;
                this.notifications.unread_count = 0;
                this.$refs.notifications.hide();
            },
            loadMoreNotifications(type) {
                if (this.notifications[type].next_page_url) {
                    this.notifications[type].loading = true;
                    this.$api.get('user/notifications/' + type, {
                        page: this.notifications[type].current_page + 1
                    }).then(response => {
                        this.mergeNotifications(type, response);
                        this.refreshUnreadCount();
                    });
                }
            },
            mergeNotifications(type, response) {
                this.notifications[type].data = this.notifications[type].data.concat(response.data.data);
                this.notifications[type].current_page = response.data.current_page;
                this.notifications[type].from = response.data.from;
                this.notifications[type].next_page_url = response.data.next_page_url;
                this.notifications[type].to = response.data.to;
                this.notifications[type].total = response.data.total;
                this.notifications[type].loading = false;
            },
            refreshUnreadCount() {
                this.notifications.unread_count = this.notifications.unread.total - this.notifications.unread.to;
            },
            /**
             * Triggers a window resize event when clicked
             * for plugins that needs to be redrawed
             */
            resize: e => {
                // all IE friendly dispatchEvent
                var evt = document.createEvent('UIEvents');
                evt.initUIEvent('resize', true, false, window, 0);
                window.dispatchEvent(evt);
                // modern dispatchEvent way
                // window.dispatchEvent(new Event('resize'));
            },
            ...mapMutations([
                'toggleSetting'
            ]),
            toggleOffsidebar() {
                this.toggleSetting('offsidebarOpen')
            },
            toggleOffcanvas() {
                this.toggleSetting('asideToggled')
            },
            toggleAsideCollapsed() {
                this.toggleSetting('isCollapsed');
                this.resize();
            },
            toggleUserBlock() {
                this.toggleSetting('showUserBlock')
            },
            reloadPage() {
                location.reload()
            },
            logout() {
                this.$auth.logout();
            },
            lock() {
                this.$auth.lock();
            }
        }
    }
</script>