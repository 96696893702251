<template>
  <div>

    <div class="row">
      <div class="col-xl-6 d-sm-none d-md-none d-lg-none d-xs-none d-xl-block">
        <div class="image-left">

        </div>
      </div>
      <div class="col-xl-6">
        <div class="wrapper layout-wall-center">

          <!-- Page content-->
          <router-view/>

        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">

  @media (max-width: 576px) {
    .d-xs-none {
      display: none;
    }
  }

  .image-left {
    height: 100%;
    background-color: black;
    position: fixed;
    width: 50%;
    bottom: 0;
    top: 0;
    left: 0;
    border-right: 30px black solid;
    background-image: url('/img/layout/background-login.jpg');
    background-size: cover;
  }

    .abs-center {
        height: 100%;
        padding-top: 3%;
    }

    .background-particles {
        top: 0;
        position: fixed;
        z-index: -1;
        left: 0;
    }
</style>
<script>


    export default {
        data() {
            return {}
        },
        mounted() {

        },
        methods: {}
    }
</script>