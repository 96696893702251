<template>
    <ContentWrapper>
        <breadcrumb v-if="plant" :name="'Plant #' + plant.label"/>
        <div class="container animated-fast fadeInUp" v-if="plant">

            <div class="card card-default">
                <div class="card-header">
                    Plant #{{ plant.label }}
                </div>
                <div class="card-body">
                    <b-row>
                        <b-col md="6" lg="4" xl="3">
                            <plant-overview-card :plant="plant"></plant-overview-card>
                        </b-col>
                        <b-col md="6" lg="8" xl="9">
                            <price-range :open="1"></price-range>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>

    </ContentWrapper>
</template>

<script>
    import PlantOverviewCard from "../components/PlantOverviewCard";
    import PriceRange from "../components/PriceRange";

    export default {
        components: {PriceRange, PlantOverviewCard},
        data() {
            return {
                plant: null,
                chart: {
                    options: {},
                    data: {}
                }
            };
        },
        mounted() {
            this.loadDetails()
        },
        methods: {
            loadDetails() {
                this.$api.get('growing/plants/' + this.$route.params.label).then(response => {

                    this.plant = response.data;

                    let data = [0];
                    let labels = [this.plant.created_at];
                    let currentHarvest = 0;

                    response.data.harvests.forEach(harvest => {
                        labels.push(harvest.transaction.created_at);
                        currentHarvest += parseFloat(harvest.amount_per_plant);
                        data.push(currentHarvest);
                    });

                    this.chart.data = {
                        labels: labels,
                        datasets: [
                            {
                                label: 'Harvested CBD (total)',
                                backgroundColor: '#000001',
                                data: data
                            }
                        ],
                    };

                    this.chart.options = {
                        legend: {
                            display: true
                        },
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                type: 'time',
                                ticks: {
                                    autoSkip: true,
                                    maxTicksLimit: 10,
                                    maxRotation: 0,
                                    minRotation: 0
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    callback: function (value) {
                                        return value + 'g';
                                    },
                                    autoSkip: true,
                                    maxTicksLimit: 5
                                }
                            }]
                        }
                    }
                })

            }
        }
    }
</script>