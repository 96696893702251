<template>

  <div class="w-100 px-2 mt-5 pb-5" style="max-width: 800px; margin: auto; overflow: hidden">
    <!-- START card-->
    <div class="text-center mb-3"><img src="img/layout/user-image.svg" width="64px"/></div>
    <div class="card card-default " :class="loading ? 'whirl' : ''">
    <div class="card-header">
            {{$t('common.view.public.recover.heading')}}:
          </div>
            <div class="card-body">

              <accordion-card>
                <template v-slot:title>
                  {{$t('common.view.public.recover.password.title')}}
                </template>

                <form @submit.prevent="validateBeforeSubmit('recover')" data-vv-scope="recover">
                  <p class="text-center">{{$t('common.view.public.recover.description')}}</p>
                  <div class="form-group">
                    <label class="text-muted" for="resetInputEmail1">{{$t('common.view.public.recover.form.email.label')}}</label>
                    <div class="input-group with-focus">
                      <input :class="{'form-control border-right-0':true, 'is-invalid': errors.has('recover.email')}" :placeholder="$t('common.view.public.recover.form.email.placeholder')" id="resetInputEmail1" v-model="recover.email" v-validate="'required|email'" type="text" name="email"/>
                      <div class="input-group-append">
                                <span class="input-group-text text-muted bg-transparent border-left-0">
                                    <em class="fad fa-envelope"></em>
                                </span>
                      </div>
                      <span v-show="errors.has('recover.email')" class="invalid-feedback">{{ errors.first('recover.email') }}</span>
                    </div>
                  </div>
                  <button class="btn btn-danger btn-block" :disabled="loading" type="submit">{{$t('common.view.public.recover.form.submit')}}</button>
                </form>

              </accordion-card>

              <div v-if="category">
                <accordion-card v-for="(entry, index) in category.questions" @opened="opened(entry)" :key="'index' + index" class="animated-fast fadeInLeft">
                  <template v-slot:title>
                    {{ entry.question }}
                  </template>

                  <formatted-html :html="entry.answer"></formatted-html>

                </accordion-card>
              </div>

              <div class="text-right">
                <router-link class="btn btn-secondary" to="login">{{$t('common.view.public.recover.form.back')}}</router-link>
              </div>

            </div>
        </div>
    </div>
</template>
<script>

    import AccordionCard from "../../modules/Growing/components/AccordionCard";
    import FormattedHtml from "@/components/Common/FormattedHtml.vue";
    export default {
      components: {FormattedHtml, AccordionCard},
      data() {
            return {
              category: null,
                recover: {
                    email: ''
                },
                loading: false,
            }
        },
      beforeMount() {
        this.$api.get('support/categories/recovery?html', {language: this.$i18n.locale}).then(response => {
          this.category = response.data;
        });
      },
      methods: {
            validateBeforeSubmit(scope) {
                if (this.loading) return;

                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        this.loading = true;
                        this.$api.post('auth/recover-password', this.recover).then(response => {
                            this.$swal.fire('Password reset link sent', 'If there is an account with the given email address in our System, you should get an E-Mail with the Password reset link now.', 'success');
                            this.$router.push({name: 'Login'});
                        }).catch(error => {
                            if (error.status === 422) {
                                this.$setErrorsFromResponse(error);
                            }
                        })
                    }
                });
            }
        }
    }
</script>
