<template>
  <div class="container mt-4">
    <div class="card">
      <div class="card-header bg-primary text-white d-flex align-items-center">
        <div class="assistant-avatar mr-3"></div>
        <h5 class="mb-0">Assistent Chat</h5>
      </div>
      <div class="card-body">

        <!-- Begrüßungsnachricht des Assistenten -->
        <div class="chat-box bg-light p-3 mb-4 border rounded">
          <div class="d-flex align-items-center mb-2">
            <div class="assistant-avatar mr-3"></div>
            <h6 class="mb-0">Assistent:</h6>
          </div>
          <p>Willkommen beim Support-Assistenten! Du kannst hier eine Frage stellen, und ich werde mein Bestes tun, um dir zu helfen.</p>  </div>

        <form @submit.prevent="askQuestion">
          <div class="form-group">
            <label for="userQuestion">Frage stellen:</label>
            <textarea
                type="text"
                style="min-height: 200px"
                id="userQuestion"
                class="form-control"
                v-model="question"
                placeholder="Geben Sie eine Frage ein ..."
                required
            ></textarea>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-primary mt-2" :disabled="loading">
              <span v-if="loading">Nachdenken ...</span>
              <span v-else>Frage stellen</span>
            </button>
          </div>
        </form>

        <div v-if="response" class="mt-4">
          <div class="d-flex align-items-center mb-3">
            <div class="assistant-avatar mr-3"></div>
            <h6 class="mb-0">Assistent:</h6>
          </div>

          <div class="border p-4 bg-light">
            <formatted-html :html="response"></formatted-html>
          </div>

          <small class="text-muted d-block text-right">
            Hinweis: Die Antworten werden von einer künstlichen Intelligenz generiert und sind möglicherweise nicht zu 100 % korrekt.
          </small>

          <div class="mt-3 text-right">
            <p>War diese Antwort hilfreich?</p>
            <button
                class="btn btn-primary btn-sm mr-2"
                :disabled="feedbackSent"
                @click="sendFeedback(true)"
            >
              Ja
            </button>
            <button
                class="btn btn-danger btn-sm mr-2"
                :disabled="feedbackSent"
                @click="sendFeedback(false)"
            >
              Nein
            </button>
            <router-link v-if="canCreateTicket" :to="{name: 'Support:Contact', params: {question: question}}" class="btn btn-warning btn-sm">
              Ticket erstellen
            </router-link>
          </div>
        </div>

        <div v-if="error" class="mt-4">
          <h6>Fehler:</h6>
          <p class="alert alert-danger">{{ error }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormattedHtml from "@/components/Common/FormattedHtml.vue";

export default {
  components: {FormattedHtml},
  data() {
    return {
      question: '', // Benutzerfrage
      response: null, // Antwort des Assistenten
      error: null, // Fehlermeldung
      loading: false, // Status für den Ladezustand
      feedbackSent: false, // Ob Feedback gesendet wurde
      inquiryId: null, // ID der aktuellen Anfrage
      canCreateTicket: false
    };
  },
  methods: {
    async askQuestion() {
      this.canCreateTicket = false;
      this.response = null;
      this.error = null;
      this.feedbackSent = false;
      this.inquiryId = null;
      this.loading = true;

      try {
        const res = await this.$api.post('support/assistant/ask-question', {
          question: this.question,
        });

        if (res.data.answer.indexOf('Support-Ticket') > -1) {
          this.canCreateTicket = true;
        }

        this.response = res.data.answer_html || 'Keine Antwort gefunden.';
        this.inquiryId = res.data.inquiry_id || null;
      } catch (err) {
        this.error = err.response?.data?.message || 'Es ist ein Fehler aufgetreten.';
      } finally {
        this.loading = false;
      }
    },
    async sendFeedback(isHelpful) {
      if (!this.inquiryId) return;

      try {
        await this.$api.post('support/assistant/give-feedback', {
          inquiry_id: this.inquiryId,
          helpful: isHelpful,
        });

        this.feedbackSent = true;
        this.error = null;
      } catch (err) {
        this.error = err.response?.data?.message || 'Fehler beim Senden des Feedbacks.';
      }
    },
  },
};
</script>

<style scoped>
.assistant-avatar {
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 50%;
  border: 2px solid #4b4b4b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: #4b4b4b;
  text-transform: uppercase;
}

.assistant-avatar::before {
  content: "AI";
}

.chat-box {
  font-size: 0.95rem;
  line-height: 1.5;
}
</style>
