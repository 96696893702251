<template>
    <div class="card-wrapper-register px-2 mt-5">
        <!-- START card-->
        <div class="text-center mb-3"><img src="img/layout/user-image.svg" width="64px"/></div>
        <div class="card card-default card-login" :class="loading ? 'whirl' : ''">
            <div class="card-header text-center py-3">
                {{$t('common.view.public.register.heading')}}
            </div>
            <div class="card-body" style="background-image: none;">
                <form class="mb-3" @submit.prevent="validateBeforeSubmit('register')" data-vv-scope="register">
                    <b-row>
                        <b-col sm="6">
                            <div class="form-group">
                                <label for="signupInputFirstname">{{$t('common.view.public.register.form.firstname.label')}}</label>
                                <div class="input-group with-focus">
                                    <input :class="{'form-control':true, 'is-invalid': errors.has('register.firstname')}" id="signupInputFirstname"
                                           :placeholder="$t('common.view.public.register.form.firstname.placeholder')" v-model="register.firstname" autofocus="autofocus" v-validate="'required'"
                                           type="text"
                                           name="firstname"/>
                                    <span v-show="errors.has('register.firstname')" class="invalid-feedback">{{ errors.first('register.firstname') }}</span>
                                </div>
                            </div>
                        </b-col>
                          <b-col sm="6">
                            <div class="form-group">
                                <label for="signupInputLastname">{{$t('common.view.public.register.form.lastname.label')}}</label>
                                <div class="input-group with-focus">
                                    <input :class="{'form-control':true, 'is-invalid': errors.has('register.lastname')}" id="signupInputLastname"
                                           :placeholder="$t('common.view.public.register.form.lastname.placeholder')" v-model="register.lastname" v-validate="'required'" type="text" name="lastname"/>
                                    <span v-show="errors.has('register.lastname')" class="invalid-feedback">{{ errors.first('register.lastname') }}</span>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                  <b-row>
                        <b-col sm="6">
                            <div class="form-group">
                                <label for="signupInputEmail1">{{$t('common.view.public.register.form.email.label')}}</label>
                                <div class="input-group with-focus">
                                    <input :class="{'form-control border-right-0':true, 'is-invalid': errors.has('register.email')}" id="signupInputEmail1"
                                           :placeholder="$t('common.view.public.register.form.email.placeholder')" v-model="register.email" autofocus="autofocus" v-validate="'required|email'"
                                           type="text"
                                           name="email"/>
                                    <div class="input-group-append">
                                <span class="input-group-text text-white bg-transparent border-left-0 pl-0">
                                    <em class="fad fa-envelope text-secondary"></em>
                                </span>
                                    </div>
                                    <span v-show="errors.has('register.email')" class="invalid-feedback">{{ errors.first('register.email') }}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signupInputname">{{$t('common.view.public.register.form.name.label')}}</label>
                                <div class="input-group with-focus">
                                    <input :class="{'form-control border-right-0':true, 'is-invalid': errors.has('register.name')}" id="signupInputname"
                                           :placeholder="$t('common.view.public.register.form.name.placeholder')" v-model="register.name" v-validate="'required'" type="text" name="email"/>
                                    <div class="input-group-append">
                                <span class="input-group-text text-white bg-transparent border-left-0 pl-0">
                                    <em class="fad fa-user  text-secondary"></em>
                                </span>
                                    </div>
                                    <span v-show="errors.has('register.name')" class="invalid-feedback">{{ errors.first('register.name') }}</span>
                                </div>
                            </div>
                        </b-col>
                        <b-col sm="6">
                            <div class="form-group">
                                <label for="signupInputPassword">{{$t('common.view.public.register.form.password.label')}}</label>
                                <div class="input-group with-focus">
                                    <input ref="password" :class="{'form-control border-right-0':true, 'is-invalid': errors.has('register.password')}" id="signupInputPassword"
                                           v-model="register.password" v-validate="'required'" type="password" name="password"
                                           :placeholder="$t('common.view.public.register.form.password.placeholder')"/>
                                    <div class="input-group-append">
                                <span class="input-group-text text-white bg-transparent border-left-0 pl-0">
                                    <em class="fad fa-lock  text-secondary"></em>
                                </span>
                                    </div>
                                    <span v-show="errors.has('register.password')" class="invalid-feedback">{{ errors.first('register.password') }}</span>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="signupInputRePasswordConfirmation">{{$t('common.view.public.register.form.password_confirm.label')}}</label>
                                <div class="input-group with-focus">
                                    <input :class="{'form-control border-right-0':true, 'is-invalid': errors.has('register.password_confirmation')}" id="signupInputRePasswordConfirmation"
                                           v-model="register.password_confirmation" v-validate="'required|confirmed:password'" type="password" name="password_confirmation"
                                           :placeholder="$t('common.view.public.register.form.password_confirm.placeholder')"/>
                                    <div class="input-group-append">
                                <span class="input-group-text text-white bg-transparent border-left-0 pl-0">
                                    <em class="fad fa-lock  text-secondary"></em>
                                </span>
                                    </div>
                                    <span v-show="errors.has('register.password_confirmation')" class="invalid-feedback">{{ errors.first('register.password_confirmation') }}</span>
                                </div>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="justify-content-end pb-3">
                        <b-col sm="6">
                            <div class="form-group">
                                <label>{{ $t('common.view.public.register.form.birthdate.label')}}</label>
                                <datepicker :use-utc="true" :bootstrap-styling="true" v-model="register.birthdate" initial-view="year" :open-date="new Date(1995,1,0)" name="birthdate" :required="true"
                                            placeholder="Enter birthdate"
                                            :format="'yyyy-MM-dd'" :typeable="true" :input-class="errors.has('register.birthdate') ? 'is-invalid' : ''">
                                </datepicker>
                                <small v-show="errors.has('register.birthdate')" class="text-danger">{{ errors.first('register.birthdate') }}</small>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="justify-content-end p-2" style="background-color: #ececec">
                        <b-col sm="11">
                            <div class="custom-control custom-checkbox text-right">
                                <input type="checkbox" :class="{'custom-control-input':true, 'is-invalid': errors.has('register.agreements')}" v-model="register.agreements" v-validate="'required'"
                                       name="agreements" id="registeragree">
                                <label class="custom-control-label" for="registeragree">
                                    {{$t('common.view.public.register.form.agree.agree')}}
                                    <a class="ml-1 text-secondary text-bold" :href="$t('common.view.settings.terms.agb_link')"  tabIndex="-1" target="_blank">{{$t('common.view.public.register.form.agree.terms')}}</a>,
                                    <a class="ml-1 text-secondary text-bold" href="https://cannergrow.com/terms/backend-service" tabIndex="-1" target="_blank">{{ $t('common.view.public.register.form.agree.rules') }}</a>
                                    {{$t('common.view.public.register.form.agree.and')}} <a class="ml-1 text-secondary text-bold" href="https://cannergrow.com/terms/backend-privacy" tabIndex="-1"
                                                                                            target="_blank">{{$t('common.view.public.register.form.agree.privacy')}}</a>
                                </label>
                                <span v-show="errors.has('register.agreements')" class="invalid-feedback">{{ errors.first('register.agreements') }}</span>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="justify-content-end p-2 my-2" style="background-color: #ececec">
                        <b-col sm="11">
                            <div class="custom-control custom-checkbox text-right">
                                <input type="checkbox" :class="{'custom-control-input':true, 'is-invalid': errors.has('register.newsletter')}" v-model="register.newsletter"
                                       name="agreements" id="register_newsletter">
                                <label class="custom-control-label" for="register_newsletter">
                                    {{$t('common.view.public.register.form.newsletter.label')}}
                                </label>
                            </div>
                        </b-col>
                    </b-row>

                    <b-row class="align-items-end">
                        <b-col sm="6">

                            <button class="btn btn-block btn-primary align-bottom" type="submit">{{$t('common.view.public.register.form.submit.label')}}</button>

                            <vue-recaptcha
                                    :sitekey="recaptcha_key"
                                    :loadRecaptchaScript="true"
                                    ref="recaptcha"
                                    @verify="submit"
                                    size="invisible"
                                    @expired="onCaptchaExpired"
                            >
                            </vue-recaptcha>
                        </b-col>
                        <b-col sm="6" class="order-sm-first">
                            <p class="pt-3 text-center">{{$t('common.view.public.register.form.login.label')}}</p>
                            <router-link class="btn btn-block btn-secondary" to="login">{{$t('common.view.public.register.form.login.link')}}</router-link>
                        </b-col>
                    </b-row>
                </form>
            </div>
        </div>

        <div class="text-center">
            <img class="mt-1 wd-lg mw-100" alt="cannergrow-logo" src="img/logo/cannerald-black.svg"/>
        </div>
    </div>
</template>
<script>
    import { VueRecaptcha } from 'vue-recaptcha';
    import Datepicker from 'vuejs-datepicker';

    export default {
        components: {VueRecaptcha, Datepicker},
        data() {
            return {
                recaptcha_key: process.env.VUE_APP_GOOGLE_RECAPTCHA,
                loading: false,
                register: {
                    ref_hash: '',
                    sponsor_name: '',
                    email: '',
                    name: '',
                    password: '',
                    password_confirmation: '',
                    birthdate: null,
                    agreements: false,
                    newsletter: false,
                    recaptcha_token: process.env.NODE_ENV === 'production' ? null : '12345678',
                    language: this.$i18n.locale
                },
                no_ref: false,
                team: ''
            }
        },
        mounted() {

        },
        methods: {
            onCaptchaExpired: function () {

            },
            validateBeforeSubmit(scope) {
                this.loading = true;
                this.$validator.validateAll(scope).then((result) => {
                    if (result) {

                        if (!this.register.ref_hash && !this.register.sponsor_name) {
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'Are you sure?',
                                text: 'You\'re going to join our platform without any team, sponsor or contact who could support you.',
                                showCancelButton: true,
                                confirmButtonText: 'No, I will contact my sponsor again!',
                                cancelButtonColor: '#4b4b4b',
                                cancelButtonText: 'Yes',
                            }).then((r) => {
                                if (r.dismiss === 'cancel') {
                                    this.checkRecaptchaToken();
                                } else {
                                    this.loading = false;
                                }
                            })
                        } else {
                            this.checkRecaptchaToken();
                        }
                    } else {
                        this.loading = false;
                    }
                });
            },
            checkRecaptchaToken() {
                if (!this.register.recaptcha_token) {
                    this.$refs.recaptcha.execute();
                } else {
                    this.submit(this.register.recaptcha_token);
                }
            },
            submit(recaptchaToken) {
                this.register.recaptcha_token = recaptchaToken;
                this.$api.post('auth/register', this.register).then(response => {
                    this.$auth.login(response.data);
                }).catch(error => {
                    if (error.status === 422) {
                        this.$setErrorsFromResponse(error.data, 'register');
                    }
                    this.loading = false;
                });
            }
        }
    }
</script>