<template>
    <footer class="footer-container">
        <div class="row">
            <div class="col-4">
              <span style="display: block; color: #868686; font-size: 0.8em;" class="p-2">
                Web-Development by <a href="https://code.gt" target="_blank">code.gt</a>
              </span>
            </div>
            <div class="col-8 d-none d-md-block">
                <ul class="social-media-links mb-0 p-0 text-center text-md-right title-xs-small title-medium">
                    <li class="list-inline-item">
                        <a href="https://facebook.com/cannerald" target="_blank">
                            <i class="cai-facebook"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://x.com/cannerald" target="_blank">
                            <i class="cai-twitter"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/cannerald/" target="_blank">
                            <i class="cai-linkedin"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://instagram.com/cannerald" target="_blank">
                            <i class="cai-instagram"></i>
                        </a>
                    </li>
                    <!--li class="list-inline-item">
                        <a href="https://crld.cc/tg" target="_blank">
                            <i class="cai-telegram"></i>
                        </a>
                    </li>
                    <li class="list-inline-item" v-show="$i18n.locale === 'de'">
                        <a href="https://t.me/cannerald_de" target="_blank" class="position-relative">
                            <i class="cai-telegram"></i>
                            <i class="fa fa-comment-dots position-absolute text-white" style="font-size: 20px; top: -1px; right: -6px;"></i>
                        </a>
                    </li-->
                    <li class="list-inline-item">
                        <a href="https://youtube.com/cannerald" target="_blank">
                            <i class="cai-youtube"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
    import LogoCanneraldSmallWhite from '@/../public/img/logo/cannerald-small-white.svg?inline';

    export default {
        name: 'Footer',
        components: {
            LogoCanneraldSmallWhite
        },
    }

</script>